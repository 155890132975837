import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { Service } from './../_model/common/service.model';
import { MaskedPrereservationRequest } from './../_model/reservations/masked-prereservation-request.model';
import { ReservationConfirmationRequest } from './../_model/reservations/reservation-confirmation-request.model';
import { ReservationInfo } from './../_model/reservations/reservation-info.model';
import { Slot } from './../_model/reservations/slot.model';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class ReservationsService {
    constructor(private http: HttpClient, private httpClient: HttpClientService, private helper: HelperService) {}

    getTokenInfo(token: string): Observable<ReservationInfo> {
        return this.httpClient.get(this.getReservationsUlr('booking/info/' + token));
    }

    public getFreeSlotsWidget(
        maskedContractorId?: string,
        subcKey?: string,
        serviceKey?: string,
        from?: moment.Moment,
        to?: moment.Moment,
        findFreeSlot: boolean = false,
    ): Observable<Slot[]> {
        const fromS: string | undefined = from?.format('YYYY-MM-DD');
        const toS: string | undefined = to?.format('YYYY-MM-DD');
        let url: string = `freeslots/contractors/${maskedContractorId}/subcontractors/${subcKey}/services/${serviceKey}`;
        if (!subcKey) {
            url = `freeslots/contractors/${maskedContractorId}/services/${serviceKey}`;
        }
        if (findFreeSlot) {
            url += `?from=${fromS}`;
        } else {
            url += `?from=${fromS}&to=${toS}`;
        }
        return this.httpClient.get(this.helper.getPatientUrl(url)).pipe(this.sortSlotsOperator());
    }

    // http://localhost:9090/booking/patient/api/freeslots/bs/1/contractors/5ORvlBVKRPGppAMOoKtqme4/services/n5-jKuha7AYM2YQM_4ZLrgo?from=2021-05-20

    public getFreeSlotsBsWidget(
        bsId: number,
        maskedContractorId: string,
        serviceKey: string,
        from: moment.Moment,
        to: moment.Moment,
        findFreeSlot: boolean = false,
        accessToken: string,
    ): Observable<Slot[]> {
        const fromS: string = from.format('YYYY-MM-DD');
        const toS: string = to.format('YYYY-MM-DD');
        let url: string = `freeslots/bs/${bsId}/contractors/${maskedContractorId}/services/${serviceKey}`;

        if (findFreeSlot) {
            url += `?from=${fromS}`;
        } else {
            url += `?from=${fromS}&to=${toS}`;
        }

        return this.httpClient.get(this.helper.getPatientUrl(url), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    public getFreeSlotsBs(
        bsId: number,
        token: string,
        from: moment.Moment,
        to: moment.Moment,
        findFreeSlot: boolean = false,
        accessToken: string,
    ): Observable<Slot[]> {
        const fromS: string = from.format('YYYY-MM-DD');
        const toS: string = to.format('YYYY-MM-DD');
        let url: string = `freeslots/bs/${bsId}/token/${token}`;

        if (findFreeSlot) {
            url += `?from=${fromS}`;
        } else {
            url += `?from=${fromS}&to=${toS}`;
        }

        return this.httpClient.get(this.helper.getPatientUrl(url), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    // http://localhost:9090/booking/patient/api/freeslots/bs/SUkg4DBqR6qBGXuq3binnws/contractors/cHB-6yBFkBzvhA4shOk-r4M?from=2021-06-18&service=MhlgNvOHh%2BkOMq77upnWWg%3D%3D'

    public getFreeSlotsWidgetExternal(
        maskedBsId: string,
        maskedContractorId: string,
        maskedExternalService: string,
        from: moment.Moment,
        to: moment.Moment,
        findFreeSlot: boolean = false,
        maskedSubcontractorId?: string,
        orgUnitId?: string,
    ): Observable<Slot[]> {
        const fromS: string = from.format('YYYY-MM-DD');
        const toS: string = to.format('YYYY-MM-DD');
        let url: string = `freeslots/bs/${maskedBsId}/contractors/${maskedContractorId}?service=${maskedExternalService}`;
        if (maskedSubcontractorId) {
            url += `&subcontractor=${maskedSubcontractorId}`;
        }

        if (orgUnitId) {
            url += `&orgid=${orgUnitId}`;
        }
        if (findFreeSlot) {
            url += `&from=${fromS}`;
        } else {
            url += `&from=${fromS}&to=${toS}`;
        }

        return this.httpClient.get(this.helper.getPatientUrl(url)).pipe(this.sortSlotsOperator());
    }

    public getContractorServiceInfo(apiKey: string, serviceKey: string): Observable<Service> {
        return this.httpClient.get(this.helper.getPatientUrl(`contractors/${apiKey}/services/${serviceKey}`));
    }

    public getSubcontractorServiceInfo(apiKey: string, subcontractorid: string, serviceKey: string): Observable<Service> {
        return this.httpClient.get(this.helper.getPatientUrl(`contractors/${apiKey}/subcontractors/${subcontractorid}/services/${serviceKey}`));
    }

    // http://localhost:9090/booking/patient/api/freeslots/contractors/5ORvlBVKRPGppAMOoKtqme4/services/lJQIvvaLMPlWe35GggTbU6o?from=2020-04-11&to=2020-04-20

    public getFreeSlots(
        token: string,
        maskedContractorId: string,
        subcontractor: number,
        service: number,
        from: Date | moment.MomentInput,
        to: Date | moment.MomentInput,
    ) {
        const params = {
            maskedContractorId: maskedContractorId,
            contractor: {
                subcontractors: [
                    {
                        id: subcontractor,
                    },
                ],
            },
            service: {
                id: service,
            },
            from: moment(from).format('YYYY-MM-DD'),
            to: moment(to).format('YYYY-MM-DD'),
        };

        return this.http.post(this.getReservationsUlr('freeslots/' + token), params);
    }

    // public getFreeSlotsWidget(data: MaskedPrereservationRequest): Observable<any> {
    //     const fromS: string = data.from.format('YYYY-MM-DD');
    //     const toS: string = data.to.format('YYYY-MM-DD');
    //     return this.httpClient.get(
    //         this.helper.getPatientUrl(
    //             `freeslots/contractors/${data.maskedContractorId}/subcontractors/${data.maskedSubcontractorId}/services/${data.maskedServiceId}?from=${fromS}&to=${toS}`,
    //         ),
    //     );
    // }

    public createMaskedWaitingQueue(data: any, grecaptcha?: string): Observable<any> {
        let params = new HttpParams();
        params = grecaptcha ? params.append('grecaptcha', grecaptcha) : params;
        return this.httpClient.post(this.getReservationsUlr('waitingqueue'), data, { params });
    }

    public createMaskedPrereservation(data: MaskedPrereservationRequest, grecaptcha?: string): Observable<ReservationConfirmationRequest> {
        let params = new HttpParams();
        params = grecaptcha ? params.append('grecaptcha', grecaptcha) : params;
        return this.httpClient.post(this.getReservationsUlr('prereservation'), data, { params });
    }

    public confirmCustomerPrereservation(prereservationid: string, guid?: string): Observable<ReservationConfirmationRequest> {
        let url: string = `reservations/${prereservationid}/confirm`;
        if (guid) {
            url += `?token=${guid}`;
        }
        return this.httpClient.post(this.getReservationsUlr(url));
    }

    //     @PostMapping("/reservations/{prereservationid}/confirm")
    // public void confirmPatientReservation(@PathVariable String prereservationid) {

    public createPrereservation(
        token: string,
        maskedContractorId: string,
        subcontractor: number,
        service: number,
        customer: number,
        from: moment.Moment,
        to: moment.Moment,
    ) {
        const params = {
            maskedContractorId: maskedContractorId,
            contractor: {
                subcontractors: [
                    {
                        id: subcontractor,
                    },
                ],
            },
            service: {
                id: service,
            },
            customer: {
                id: customer,
            },
            from: moment(from).toISOString(),
            to: moment(to).toISOString(),
        };

        return this.http.post(this.getReservationsUlr('prereservation/' + token), params);
    }

    // public createPrereservation(
    //     token: string,
    //     contractor: number,
    //     subcontractor: number,
    //     service: number,
    //     customer: number,
    //     from: Date | moment.MomentInput,
    //     to: Date | moment.MomentInput,
    // ): Observable<any> {
    //     const params = {
    //         contractor: {
    //             id: contractor,
    //             subcontractors: [
    //                 {
    //                     id: subcontractor,
    //                 },
    //             ],
    //         },
    //         service: {
    //             id: service,
    //         },
    //         customer: {
    //             id: customer,
    //         },
    //         from: moment(from).toISOString(),
    //         to: moment(to).toISOString(),
    //     };

    //     return this.http.post(this.getReservationsUlr('prereservation/' + token), params);
    // }

    public deletePrereservation(aggregateId: number, prereservationId: number) {
        return this.http.delete(this.getErpUrl('prereservation/' + aggregateId + '/' + prereservationId));
    }

    public widgetConfirmPrereservation(data: ReservationConfirmationRequest, confirmationMail: boolean = true): Observable<any> {
        return this.httpClient.post(this.getReservationsUlr('reservation') + `?confirmationMail=${confirmationMail}`, data);
    }

    public widgetVendorConfirmPrereservation(data: ReservationConfirmationRequest, bookingAllowanceToken?: string): Observable<any> {
        let url = this.getReservationsUlr('vendor/reservation');
        if (bookingAllowanceToken) {
            url += `?token=${bookingAllowanceToken}`;
        }
        return this.httpClient.post(url, data);
    }

    public confirmPrereservation(token: string, aggregateId: string, prereservationId: string) {
        const params = {
            aggregateId: aggregateId,
            prereservationId: prereservationId,
        };

        return this.http.post(this.getReservationsUlr('reservation/' + token), params);
    }

    public uploadDocuments(grecaptcha: string, documentation: File[]) {
        let formData = new FormData();
        documentation.map(el => formData.append('files', el));

        return this.httpClient.postUploadFile(this.getReservationsUlr(`upload?grecaptcha=${grecaptcha}`), formData);
    }

    public getReservationsUlr(path?: string) {
        if (!path) {
            path = '';
        }
        return environment.service.protocol + environment.service.rootUrl + environment.service.apiPort + environment.service.apiUrl + '/' + path;
    }

    public getErpUrl(path?: string) {
        if (!path) {
            path = '';
        }
        return environment.service.protocol + environment.service.rootUrl + environment.service.apiPort + environment.service.erpApiUrl + '/' + path;
    }

    private sortSlotsOperator() {
        return function (source: Observable<Slot[]>): Observable<Slot[]> {
            return new Observable(subscriber => {
                return source.subscribe({
                    next(value) {
                        if (_.isArray(value)) {
                            subscriber.next(
                                value.sort((a, b) => {
                                    return moment(a.start).diff(b.start);
                                }),
                            );
                        } else {
                            subscriber.next(value);
                        }
                    },
                    error(error) {
                        subscriber.error(error);
                    },
                    complete() {
                        subscriber.complete();
                    },
                });
            });
        };
    }
}
